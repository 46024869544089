import PageHeader from '@/components/pageHeader/pageHeader'
import RefreshCheckbox from '@/components/refreshCheckbox/refreshCheckbox'
import { Button } from '@/components/ui/button'
import { teramotOnePhoneNumber } from '@/constants/common'

import useGoLive from './goLive.hook'

const GoLive = () => {
  const {
    t,
    stage,
    activateWhatsapp,
    users,
    subscription,
    isActiveSubscription,
    usersLimit,
    inputs,
    inputsLimit,
    project,
    configuration,
    readyToSubmit,
    handleSubmit,
    QRCode,
    activateTeramot,
    statusSomeWarehouseAvl,
    isRefreshChecked,
    setIsRefreshChecked,
    disabledCheckbox,
  } = useGoLive()

  const inValidClass =
    'relative text-text-error text-bold border border-text-error px-[40px] rounded caption2'

  return (
    <PageHeader subTitle={t('reviewAndDeploy')}>
      <div className="w-full sm:px-8">
        <div className="w-full flex flex-col lg:flex-row p-8  rounded-lg gap-6">
          <div className="flex flex-col gap-4">
            {/* SUBSCRIPTION */}
            <div className="w-full">
              {!statusSomeWarehouseAvl && (
                <div className="w-full flex gap-2">
                  <p className="sm">{t('dataWarehouse')}:</p>
                  <p className="sm font-medium">{t('processingWarehouse')}</p>
                </div>
              )}
              <div className="w-fit pb-8">
                <RefreshCheckbox
                  disabled={disabledCheckbox}
                  isChecked={isRefreshChecked}
                  onCheckedChange={() => setIsRefreshChecked((prev) => !prev)}
                />
              </div>
              <p className="caption2">{t('subscription')}</p>
              <div className="pl-5 w-full flex flex-col gap-1 mt-1">
                {subscription?.tierName && subscription?.status ? (
                  <>
                    <div className="w-full grid grid-cols-[100px_1fr] items-center">
                      <p className="sm">{t('subscription')}:</p>
                      <p className="sm font-medium">{subscription.tierName}</p>
                    </div>
                    {isActiveSubscription ? (
                      <div className="w-full grid grid-cols-[100px_1fr] items-center">
                        <p className="sm">{t('status')}:</p>
                        <p className="sm font-medium">{subscription.status}</p>
                      </div>
                    ) : (
                      <div className="flex">
                        <p className={inValidClass}>
                          {t('status')}: {subscription.status}
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="flex">
                    <p className={inValidClass}>
                      {t('subscription')}: {t('none')}
                    </p>
                  </div>
                )}
              </div>
            </div>

            {/* OUTPUT CHANEL */}
            <div className="w-full">
              <p className="caption2">{t('outputChannels')}</p>

              <div className="pl-5 w-full flex flex-col gap-1 mt-1">
                {activateWhatsapp ? (
                  <div className="w-full grid grid-cols-[100px_1fr] items-center">
                    <p className="sm">Whatsapp:</p>
                    <p className="sm font-medium">
                      {teramotOnePhoneNumber[stage as 'server' | 'staging']}
                    </p>
                  </div>
                ) : null}
                {activateTeramot ? (
                  <div className="w-full grid grid-cols-[100px_1fr] items-center">
                    <p className="sm">Teramot:</p>
                    <p className="sm font-medium">{t('active')}</p>
                  </div>
                ) : null}
                {!activateWhatsapp && !activateTeramot ? (
                  <div className="flex">
                    <p className={inValidClass}>
                      {t('outputChannels')}: {t('none')}
                    </p>
                  </div>
                ) : null}

                {users <= usersLimit ? (
                  <>
                    {users === 0 ? (
                      <div className="flex">
                        <p className={inValidClass}>
                          {t('users')}: {users} / {usersLimit}
                        </p>
                      </div>
                    ) : (
                      <div className="w-full grid grid-cols-[100px_1fr] items-center">
                        <p className="sm">{t('users')}:</p>
                        <p className="sm font-medium">
                          {users} / {usersLimit}
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="flex">
                    <p className={inValidClass}>
                      {t('users')}: {users} / {usersLimit}
                    </p>
                  </div>
                )}
              </div>
            </div>

            {/* INPUT DATA */}
            <div className="w-full">
              <p className="caption2"> {t('inputData')}</p>
              <div className="pl-5 w-full flex flex-col gap-1 mt-1">
                {inputs <= inputsLimit ? (
                  inputs === 0 ? (
                    <div className="flex">
                      <p className={inValidClass}>
                        {t('number')}: {inputs} / {inputsLimit}
                      </p>
                    </div>
                  ) : (
                    <div className="w-full grid grid-cols-[100px_1fr]">
                      <p className="sm">{t('number')}:</p>
                      <p className="sm font-medium">
                        {inputs} / {inputsLimit}
                      </p>
                    </div>
                  )
                ) : (
                  <div className="flex">
                    <p className={inValidClass}>
                      {t('number')}: {inputs} / {inputsLimit}
                    </p>
                  </div>
                )}
              </div>
              <div className="pl-10 w-full flex flex-col gap-1 mt-1 overflow-auto">
                {Object.keys(project?.inputData || {}).map((iid) => (
                  <div className="w-full grid grid-cols-[100px_1fr]" key={iid}>
                    <p className="sm">{t('name')}:</p>
                    <p className="sm font-medium whitespace-nowrap">
                      {project?.inputData[iid].name} {' / '}
                      {project?.inputData[iid].type}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            {/* CONFIGURATION */}
            <div className="w-full">
              <p className="caption2">{t('configuration')}</p>
              <div className="pl-5 w-full flex flex-col gap-1 mt-1">
                {configuration?.botName ? (
                  <div className="w-full grid grid-cols-[100px_1fr] items-center">
                    <p className="sm">{t('botName')}:</p>
                    <p className="sm font-medium">{configuration.botName}</p>
                  </div>
                ) : (
                  <div className="flex">
                    <p
                      className={inValidClass}
                      data-error={t('configurationParametersNeeded')}
                    >
                      {t('noData')}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-[150px] h-[150px] rounded-lg overflow-hidden mx-auto shrink-0">
            {activateWhatsapp && (
              <img alt="QR Code" className="qr-image" src={QRCode} />
            )}
          </div>
        </div>
      </div>

      <div className="w-full flex justify-center mt-8">
        <Button disabled={!readyToSubmit} onClick={handleSubmit} type="button">
          {' '}
          🟢 {t('deployButtonMessage')}
        </Button>
      </div>
    </PageHeader>
  )
}

export default GoLive
