import { useWarningBeforeLeave } from '@/hooks/useValidateCreateDatasetBeforeLeave'

import { useDatasetCreateContext } from '../datasetCreate.context'
import { UseUpdateColumnLeavePageProps } from './updateColumn.props'

export const useUpdateColumnLeavePage = (
  props: UseUpdateColumnLeavePageProps,
) => {
  const { meta } = props
  const { setStep, setCurrentDataset } = useDatasetCreateContext()

  const validate = () => {
    return Object.keys(meta).length > 0
  }

  const { blocker } = useWarningBeforeLeave({
    validate,
    setStep,
    setCurrentDataset,
  })

  return { blocker }
}
