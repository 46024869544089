/* eslint-disable @typescript-eslint/no-explicit-any */

import { ANY } from './common.type'

export interface IProjectInputTable {
  iid?: string
  name: string
  columns: string[]
  selectedColumns: Record<string, boolean>
  descriptions: Record<string, string>
  columnDataTypes: Record<string, string>
}
export interface IProjectInputData {
  name: string
  path: string
  description: string
  columns: string[]
  selectedColumns: Record<string, boolean>
  descriptions: Record<string, string>
  columnDataTypes: Record<string, string>
  fileObjectKey: string
  tables: IProjectInputTable[]
  iid: string
  createdAt: string
  type: string
  filteredDescriptions: Record<string, { description: string }>
  parquetFileObjectKey: string
  text: string
  etl: IETL
  updatedAt: string
}

export interface IProjectInputDataWithInputId extends IProjectInputData {
  input_id: string
}

interface Knowledge {
  [key: string]: string[]
}

export interface IProjectConfigurationData {
  botName: string
  lang: string
  knowledge: Knowledge
  selectedReports: Record<string, boolean>
  knowledgeGeneral: string[]
}

export interface ISubscription {
  tierName: string
  pid: string
  email: string
  priceId: string
  customerId: string
  status: string
  subscriptionId: string
  subitemId: string
  sessionId: string
}

export interface ChannelInfo {
  n_interaction: number
  total_cost: number
}

interface UserInfo {
  user: string
  channel_info: Record<string, ChannelInfo>
}

interface CurrentMonth {
  channel_info: Record<string, ChannelInfo>
  user_info: UserInfo[]
}

interface Interactions {
  current_month: CurrentMonth
}

export interface ITable {
  table_id: string
  columns: Array<string> // get column selected
  descriptions: Record<string, string>
  columnDataTypes: Record<string, string>
  name: string
  selectedColumns: Record<string, boolean>
}

interface IDataset {
  input_id: string
  name: string
  description: string
  tables: Array<ITable>
}

export enum EETLStatus {
  SUCCESSFUL = 'SUCCESSFUL',
  PENDING = 'PENDING',
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  ERROR = 'ERROR',
  OUTDATED = 'OUTDATED',
}

interface IETL {
  id: string
  status: EETLStatus
  updatedAt?: string
  createdAt?: string
  columns?: Array<IProcessedDataColumn>
  description?: string
  lastModelDescription?: string
}
export interface IWarehouse {
  id?: string
  name: string
  description: string
  refresh: boolean
  sources: Array<IDataset>
  knowledge: Array<{ _id: string; content: string }>
  questions: Array<{ _id: string; content: string }>
  etl: IETL
  singleData: boolean
  updatedAt: string
}

export interface IProcessedDataColumn {
  id: string
  name: string
  description: string
  findings: Array<ANY>
  selected?: boolean
}
export interface IProcessedData {
  id: string
  created_at: string
  updated_at: string
  layer_id: string
  name: string
  status: string
  columns: Array<IProcessedDataColumn>
  code: string
  questions: ANY
  knowledges: ANY
  details?: {
    message: string
    [key: string]: ANY
  }
  description?: string
  lastModelDescription?: string
}
export interface IProject {
  version: string
  users: string[]
  guests: string[]
  isDeployed: boolean
  chatDisabled: boolean
  guestProject: boolean
  testQuestions: []
  _id: string
  pid: string
  name: string
  description: string
  company: string
  author: string
  coverFile: string
  inputData: Record<string, IProjectInputData>
  outputChannels?: any
  subscription?: ISubscription
  configuration?: IProjectConfigurationData
  needDeploy?: any
  questions: string[]
  interactions?: Interactions
  warehouse?: Array<IWarehouse>
  processedData?: Array<IProcessedData>
  goldTables?: Array<IProcessedData>
  etlID?: string
  lang: string
  schedulerData?: Record<string, string>
}
