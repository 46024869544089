import { useEffect, useState } from 'react'

import {
  clearPreviewTable,
  getPreviewTable,
} from '@/features/project/projectSlice'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { useGetCurrentProject } from '@/hooks/useGetCurrentProject/useGetCurrentProject'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'

export const usePreviewTable = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [hasData, setHasData] = useState(false)
  const previewTable = useAppSelector((state) => state.project.previewTable)

  const { t } = useTrans()

  const { params } = useRouter()
  const dispatch = useAppDispatch()
  const { id } = params
  const { project } = useGetCurrentProject()
  const selectedWarehouse = project.warehouse?.find((w) => w.id === id)
  const etlID = selectedWarehouse?.etl?.id
  const selectedTable = previewTable.find((table) => table.etlID === etlID)

  useEffect(() => {
    if (!etlID) return

    const fetchData = async () => {
      setIsLoading(true)
      setErrorMessage(null)
      try {
        dispatch(clearPreviewTable())
        await dispatch(getPreviewTable(etlID))
      } catch (error) {
        console.error('Error fetching preview table:', error)
        setErrorMessage(t('previewTableError'))
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [dispatch, etlID, t])

  useEffect(() => {
    setHasData(
      !!(
        selectedTable &&
        selectedTable.data?.length > 0 &&
        selectedTable.etlID === etlID
      ),
    )
  }, [selectedTable, etlID])

  return {
    isLoading,
    errorMessage,
    hasData,
    selectedTable,
  }
}
