import { useTrans } from '@/hooks/useTranslation'
import yup from '@/validates'

const projectEditValidate = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTrans()

  return {
    schema: yup.object().shape({
      thumbnail: yup.mixed().nullable(),
      name: yup
        .string()
        .required(t('nameRequired'))
        .min(3, t('projectNameError')),
      description: yup.string().optional(),
      language: yup.string().required(t('languageRequired')),
      email: yup
        .array()
        .min(1, t('emailRequired'))
        .required(t('emailRequired'))
        .of(
          yup
            .string()
            .email(t('emailInvalid'))
            .required(t('emailRequired'))
            .matches(
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              'Email is not valid domain',
            ),
        ),
    }),
  }
}

export default projectEditValidate
