import { EETLStatus, ELang } from '@/types'

export const BASE_URL = import.meta.env.VITE_API_URL
export const VITE_INCLUDE_DEMO = import.meta.env.VITE_INCLUDE_DEMO
export const VITE_GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID
export const VITE_NODE_ENV = import.meta.env.VITE_NODE_ENV
export const MAXIMUM_SELECT_HEADER = 200
export const CHATBOT_SOCKET_PATH = '/backend/ws'
export const CHATBOT_SOCKET_EMIT = {
  CHAT_HISTORY: 'chat history',
  CHAT_MESSAGE: 'chat message',
}
export const CHATBOT_SOCKET_LISTEN = {
  CHAT_MESSAGE: 'chat message',
}

/**
 * SM: 640px
 * MD: 768px
 * LG: 1024px
 * XL: 1280px
 * XXL: 1536px
 */
export const BREAKPOINTS = {
  SM: 640,
  MD: 768,
  LG: 1024,
  XL: 1280,
  XXL: 1536,
}

export const ENV_VITE = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  STAGING: 'staging',
}

export const QR_URL_PROD = '/qr-code-server.png'
export const QR_URL_DEV = '/qr-code-staging.png'

export const teramotOnePhoneNumber = {
  server: '+54 9 11 2409-5219',
  staging: '+54 9 294 463-2725',
}

export const tiersOptions = [
  {
    key: 'Explorer',
    name: 'Tier Explorer',
    list: [
      '4 users',
      '1 dataset',
      '400 questions per month',
      'Spreedsheets',
      'Connect Databases',
    ],
    pricing: '$1000',
    usersLimit: 4,
    inputLimit: 1,
    questionsLimit: 400,
  },
  {
    key: 'Pro',
    name: 'Tier Pro',
    list: [
      '20 users',
      '4 datasets',
      '2000 questions per month',
      'Additional questions on demand',
      'All data connections',
      'Transcribe Audios',
    ],
    pricing: '$4000',
    usersLimit: 20,
    inputLimit: 4,
    questionsLimit: 2000,
  },
  {
    key: 'Business',
    name: 'Tier Business',
    list: [
      '100 users',
      '10 datasets',
      '10000 questions per month',
      'Additional questions on demand',
      'All data connections',
      'Trancribe Audios',
      'Access using API',
    ],
    pricing: '$8000',
    usersLimit: 100,
    inputLimit: 10,
    questionsLimit: 10000,
  },
]

export const gptModelKey = {
  'gpt-3.5-turbo': 'gpt-3.5-turbo',
  'gpt-3.5-turbo-16k': 'gpt-3.5-turbo-16k',
  'gpt-4': 'gpt-4',
}

export const gptModelKeyToName = {
  // [gptModelKey['gpt-3.5-turbo']]: 'GPT-3.5 Turbo',
  // [gptModelKey['gpt-3.5-turbo-16k']]: 'GPT-3.5 Turbo 16k',
  [gptModelKey['gpt-4']]: 'GPT-4',
}

export const languageKey = {
  en: 'English',
  es: 'Español',
}

export const browserCacheKey = {
  questions: 'questions',
  tables: 'tables',
}

export enum userRole {
  admin = 'admin',
  author = 'author',
  member = 'member',
  guest = 'guest',
}

export const defaultLanguage = ELang.en

export const channels = {
  whatsapp: 'WhatsApp',
  telegram: 'Telegram',
  facebook: 'Facebook Messenger',
  webapp: 'Teramot',
}

export const ELT_POLLING_STATUS = [EETLStatus.PENDING, EETLStatus.IN_PROGRESS]
