import { Button } from '@/components/ui/button'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useTrans } from '@/hooks/useTranslation'
import {
  TableFormFactory,
  TableFormFactoryProvider,
} from '@/pages/dataset/tableFormFactory'

import { useChangeStepUpdateTable } from '../updateTable.hook'
import { useUpdateTableView } from './updateTableView.hook'
import { UpdateTableViewProps } from './updateTableView.props'

const UpdateTableView = (props: UpdateTableViewProps) => {
  const { form, detail } = props
  const { t } = useTrans()
  const { handleReplace } = useChangeStepUpdateTable()
  useUpdateTableView({ form, detail })

  return (
    <div className="w-full">
      <div className="w-full p-6 border border-slate-200 rounded-md mt-8 flex flex-col gap-4">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel className="font-semibold" requiredField>
                  {t('name')}
                </FormLabel>
                <FormControl>
                  <Input
                    disabled
                    {...field}
                    placeholder={t('placeholderHost')}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold">
                {t('description')}
              </FormLabel>
              <FormControl>
                <Input {...field} placeholder={t('placeholderDescription')} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-col md:flex-row">
          <div className="flex-grow w-full">
            <FormField
              control={form.control}
              name="fileObjectKey"
              render={({ field }) => {
                const _value = field?.value?.split?.('/')?.pop() ?? ''
                return (
                  <FormItem>
                    <FormLabel className="font-semibold" requiredField>
                      {t('fileName')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        disabled
                        placeholder={t('typeYourFileNameHere')}
                        value={_value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )
              }}
            />
          </div>
          <div className="flex flex-col items-end justify-start md:justify-end md:px-4">
            <Button
              className="!border-none !shadow-none !bg-transparent !text-secondary-1"
              onClick={handleReplace}
              variant={'outline'}
            >
              {t('replaceFile')}
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div className="">
          <h4 className="h4 text-text-gray my-6">{t('tables')}</h4>
          <div className="grid gap-4">
            <TableFormFactoryProvider form={form}>
              <FormField
                control={form.control}
                name="tables"
                render={({ field }) => {
                  if (!field.value || !field.value.length) return <div></div>
                  const _ = field.value

                  return <TableFormFactory list={_} />
                }}
              />
            </TableFormFactoryProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateTableView
