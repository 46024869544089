import { CheckedState } from '@radix-ui/react-checkbox'
import { Outlet } from 'react-router'

import SubSidebar from '@/components/subSidebar/subSidebar'
import { Checkbox } from '@/components/ui/checkbox'

import useTuning from './tuning.hook'

const Tuning = () => {
  const { t, subSidebarLinks, checkedWarehouses, handleCheckboxChange } =
    useTuning()

  const subSidebarCheckbox = subSidebarLinks.map((wh, index) => {
    return {
      ...wh,
      checkbox:
        index !== 0 ? (
          <Checkbox
            checked={checkedWarehouses[wh.key] as CheckedState | undefined}
            onClick={() => handleCheckboxChange(wh.key)}
          />
        ) : (
          ''
        ),
    }
  })

  return (
    <div className="w-full grow flex h-full">
      <SubSidebar buttons={subSidebarCheckbox} title={t('knowledge')} />
      <Outlet />
    </div>
  )
}

export default Tuning
