import { useTrans } from '@/hooks/useTranslation'
import yup from '@/validates'

export const useQuicksightChannelValidate = () => {
  const { t } = useTrans()

  return {
    schema: yup.object().shape({
      activateQuicksight: yup.boolean(),
      activateBusiness: yup.boolean(),
      businessAllEmails: yup.boolean(),
      businessID: yup.string().when('activateBusiness', {
        is: true,
        then: () => yup.string().required(t('businessIdRequireActiveBusiness')),
      }),
      graphQLURL: yup.string().when('activateBusiness', {
        is: true,
        then: () => yup.string().required(t('graphQLURLRequireActiveBusiness')),
      }),
      graphQLToken: yup.string().when('activateBusiness', {
        is: true,
        then: () =>
          yup.string().required(t('graphQLTokenRequireActiveBusiness')),
      }),
      emails: yup.array().of(
        yup.object().shape({
          email: yup
            .string()
            .email(t('emailInvalid'))
            .required(t('emailRequired')),
          name: yup.string().required(t('nameRequired')),
          checked: yup.boolean().default(false),
        }),
      ),
    }),
  }
}
