import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  getProjects,
  getWarehouseStatus,
} from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { useGetCurrentProject } from '@/hooks/useGetCurrentProject/useGetCurrentProject'
import { useRouter } from '@/hooks/useRouter'
import { EETLStatus } from '@/types'

export const useDataWarehouse = () => {
  const ref_scrollElement = useRef(null)
  const { params, navigate } = useRouter()
  const { project, isEmptyProject } = useGetCurrentProject()
  const { pid, id } = params
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    dispatch(getProjects({}))
  }, [dispatch])

  useEffect(() => {
    if (ref_scrollElement.current) {
      ;(ref_scrollElement.current as HTMLElement).scrollTo(0, 0)
    }
  }, [id])

  const handleWarehouseClick = useCallback(
    async (warehouseId: string) => {
      setLoading(true)
      await dispatch(getWarehouseStatus(pid!))
      setLoading(false)
      navigate(`/project/${pid}/data-warehouse/${warehouseId}`)
    },
    [dispatch, navigate, pid],
  )

  const warehouses = useMemo(() => {
    const datasetEntries = project?.warehouse ?? []
    return datasetEntries?.reduce(
      (acc, warehouse) => {
        if (!warehouse.id) return acc
        acc.push({
          key: warehouse.id as string,
          label: warehouse.name as string,
          status: warehouse?.etl?.status,
          onClick: () => {
            handleWarehouseClick(warehouse.id as string)
          },
        })
        return acc
      },
      [] as {
        key: string
        label: string
        status: EETLStatus
        onClick: () => void
      }[],
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(project)])

  const firstWarehouse = useMemo(() => {
    const datasetEntries = project?.warehouse ?? []
    return datasetEntries?.[0] ?? null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouses])

  const goToCreateDataWarehouse = () => {
    navigate(`/project/${pid}/data-warehouse/create`)
  }

  const meta = {
    firstWarehouse,
    pid,
    project,
    warehouses,
    isEmptyProject,
    ref_scrollElement,
    goToCreateDataWarehouse,
    loading,
  }

  return meta
}
