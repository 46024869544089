import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons'

import { Button } from '@/components/ui/button'
import {
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Form } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useTrans } from '@/hooks/useTranslation'

import { useBasicInfoForm } from './basicInfoForm.hook'
import { BasicInfoFormProps } from './basicInfoForm.props'

const BasicInfoForm = (props: BasicInfoFormProps) => {
  const { toggleEdit } = props
  const { form, onSubmit } = useBasicInfoForm(props)
  const { t } = useTrans()
  return (
    <Form {...form}>
      <form className="w-full mt-2 space-y-4" onSubmit={onSubmit}>
        <FormField
          control={form.control}
          name="botName"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold" requiredField>
                {t('botName')}
              </FormLabel>
              <Input
                id={props.IdFocus}
                placeholder={t('placeholderBotName')}
                {...field}
              />
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="w-full flex justify-end gap-2 mt-4">
          <Button borderRadius="large">
            <CheckIcon className="h-4 w-4" />
          </Button>
          <Button
            borderRadius="large"
            className="border border-n-2"
            onClick={toggleEdit}
            type="button"
            variant="link"
          >
            <Cross2Icon className="h-4 w-4" />
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default BasicInfoForm
