import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

import logo from '@/assets/images/logos/logo.svg'
import { cn } from '@/lib/utils'
import { ANY } from '@/types'

import {
  IMessageItemProps,
  IMessageSystem,
  MESSAGE_TYPE,
} from './messageItem.props'

const parseImageFromMessage = (text: string) => {
  if (!text) return ''

  const regex = /\[IMAGE_URL\](.*?)(?=\[IMAGE_URL\]|$)/gs
  const newText = text.replace(
    regex,
    (match, url) =>
      `<img class="rounded block my-3 mb-0" src="${url.trim()}" alt="message picture" />`,
  )
  return newText
}

const MessageReceiver = (props: IMessageItemProps) => {
  const { text } = props
  return (
    <div className="pr-[56px]">
      <div className="flex gap-2">
        <div className="p-2 bg-n-2 rounded-sm rounded-es-none">
          <div
            className="
            text-xs text-black font-medium max-w-[300px] sm:max-w-[300px]
            md:max-w-[400px] lg:max-w-[550px] overflow-x-auto
          "
          >
            <Markdown
              rehypePlugins={[rehypeRaw as ANY]}
              remarkPlugins={[remarkGfm]}
            >
              {parseImageFromMessage(text as string)}
            </Markdown>
          </div>
        </div>
      </div>
    </div>
  )
}

const MessageSender = (props: IMessageItemProps) => {
  const { text } = props
  return (
    <div className="pl-[100px] flex justify-end">
      <div className="p-2 bg-accent-1 rounded-sm rounded-ee-none">
        <div
          className="
            text-xs text-white font-medium max-w-[300px] sm:max-w-[300px]
            md:max-w-[400px] lg:max-w-[550px] overflow-x-auto
          "
        >
          {text}
        </div>
      </div>
    </div>
  )
}

export const MessageSystem = (props: IMessageSystem) => {
  const { buttons, fillColor } = props

  const btnClass = cn(
    'w-full px-3- py-2 flex justify-center bg-n-1 border-n-2 border rounded-2xl cursor-pointer',
    { 'bg-secondary-1 text-white [&>*]:text-white': fillColor },
  )

  const onClick = (messageSelected: Record<string, unknown>) => {
    props.onClickButton(messageSelected)
  }

  return (
    <div className="w-full">
      <div className="flex flex-col gap-2">
        {buttons.map((c, index) => {
          return (
            <div className={btnClass} key={index} onClick={() => onClick(c)}>
              <div className="text-xs text-black font-medium">
                {c.content || ''}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const MessageAttachment = (props: IMessageItemProps) => {
  const { text, file } = props
  return (
    <div className="pr-[56px]">
      <div className="flex gap-2">
        <div className="flex-shrink-0 w-8 h-8 p-2 rounded-full flex justify-center items-center bg-primary-1 mt-auto">
          <img
            alt="Logo"
            className="w-full h-auto"
            src={logo as unknown as string}
          />
        </div>
        <div className="w-full p-2 bg-n-2 rounded-sm rounded-es-none">
          <div className="text-xs text-black mb-2.5">{text}</div>
          <div className="w-full p-2 bg-white rounded-md flex gap-2">
            <div className="w-9 h-9 bg-green-400 rounded-md"></div>
            <div className="flex flex-col gap-1">
              <div className="text-xs text-black font-bold truncate line-clamp-1">
                {file?.path}
              </div>
              <div className="text-xs text-secondary-1 font-semibold truncate line-clamp-1">
                {file?.name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const MessageItem = (props: IMessageItemProps) => {
  const { sender } = props
  switch (sender) {
    case MESSAGE_TYPE.BOT:
      return <MessageReceiver {...props} />
    case MESSAGE_TYPE.USER:
      return <MessageSender {...props} />
    case MESSAGE_TYPE.BOT_ATTACHMENT:
      return <MessageAttachment {...props} />
    default:
      return <MessageReceiver {...props} />
  }
}

export default MessageItem
