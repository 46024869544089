import { useEffect } from 'react'

import { confirmRodal } from '@/components/rodal'
import Rodal from '@/components/rodal/rodal'
import { CREATE_DATASET_STEP } from '@/pages/dataset/datasetCreate/datasetCreate.constant'

import useNavBlocker from '../useNavBlocker/useNavBlocker'
import { useTrans } from '../useTranslation'
import {
  UseValidateCreateDatasetBeforeLeaveProps,
  UseValidateCreateDatasetBeforeLeaveReturn,
} from './useValidateCreateDatasetBeforeLeave.props'

export const useWarningBeforeLeave = (
  props: UseValidateCreateDatasetBeforeLeaveProps,
): UseValidateCreateDatasetBeforeLeaveReturn => {
  const { validate, dependencies, setStep, setCurrentDataset } = props
  const { blocker } = useNavBlocker({
    cb: () => {
      return (location) => {
        return validate(location) as boolean
      }
    },
    dependencies,
  })

  const { t } = useTrans()

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirmRodal({
        children: ({ onClose }) => {
          const _onClose = () => {
            onClose()
          }
          return (
            <Rodal
              message={t('unsavedChanges')}
              onCancel={_onClose}
              onClose={_onClose}
              onConfirm={() => {
                if (setStep && setCurrentDataset) {
                  setStep(CREATE_DATASET_STEP.NAME)
                  setCurrentDataset(undefined)
                }
                blocker.proceed()
              }}
              title={t('warning')}
            />
          )
        },
      })
    }
  }, [blocker, setCurrentDataset, setStep, t])

  return { blocker }
}
