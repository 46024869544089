import 'react-toastify/dist/ReactToastify.css'
import '../App.css'

import { GoogleOAuthProvider } from '@react-oauth/google'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { ProjectLayout } from '@/components/layout'
import {
  userRole,
  VITE_GOOGLE_CLIENT_ID,
  VITE_INCLUDE_DEMO,
} from '@/constants/common'
import { SessionProvider } from '@/contexts/sessionContext'
import { isAuth } from '@/helpers/auth'
import Activate from '@/pages/Activate/Activate'
import Auth from '@/pages/Auth/Auth'
import Billing from '@/pages/billing/billing'
import BillingCongratulation from '@/pages/billingCongratulation/billingCongratulation'
import BillingQr from '@/pages/billingQr/billingQr'
import ComponentDemo from '@/pages/ComponentDemo'
import Conversation from '@/pages/conversation/conversation'
import Dataset from '@/pages/dataset/dataset'
import HOCDataSet from '@/pages/dataset/dataset.hoc'
import CreateDatasetContainer from '@/pages/dataset/datasetCreate/datasetCreate'
import UpdateDatasetContainer from '@/pages/dataset/datasetUpdate/datasetUpdate'
import HOCDataWarehouse from '@/pages/dataWarehouse/dataset.hoc'
import DataWarehouse from '@/pages/dataWarehouse/dataWarehouse'
import DataWarehouseCreateContainer from '@/pages/dataWarehouse/dataWarehouseCreate/dataWarehouseCreate'
import DataWarehouseUpdateContainer from '@/pages/dataWarehouse/dataWarehouseUpdate/dataWarehouseUpdate'
import ForgotPassword from '@/pages/forgotPassword/forgotPassword'
import GoLive from '@/pages/goLive/goLive'
import NotFound from '@/pages/notFound/notFound'
import Projects from '@/pages/Project/Projects'
import ProjectEdit from '@/pages/projectEdit/projectEdit'
import Question from '@/pages/question/question'
import ResetPassword from '@/pages/resetPassword/ResetPassword'
import Tuning from '@/pages/tuning/tuning'
import TuningDataset from '@/pages/tuning/tuningDataset/tuningDataset'
import TuningGeneral from '@/pages/tuning/tuningGeneral/tuningGeneral'
import Usage from '@/pages/usage/usage'
import QuicksightChannel from '@/pages/usersChannel/quicksightChannel/quicksightChannel'
import TeramotChannel from '@/pages/usersChannel/teramotChannel/teramotChannel'
import UsersChannel from '@/pages/usersChannel/usersChannel'
import WhatsappChannel from '@/pages/usersChannel/whatsappChannel/whatsappChannel'
import UserSetting from '@/pages/userSetting/userSetting'
import { AdminRoute, PrivateRoute, PublicRoute } from '@/routes'
import { RolesProjectRoute } from '@/routes/rolesProjectRoute'

const AppWithProviders = () => {
  return (
    <SessionProvider>
      <GoogleOAuthProvider clientId={VITE_GOOGLE_CLIENT_ID}>
        <div
          className="App min-h-screen"
          style={
            isAuth()
              ? { backgroundColor: 'white' }
              : { backgroundColor: 'rgb(242, 245, 248)' }
          }
        >
          <Outlet />
        </div>
        <ToastContainer />
      </GoogleOAuthProvider>
    </SessionProvider>
  )
}

const AppWithRouterProvider = () => {
  const router = createBrowserRouter([
    {
      path: '',
      element: <AppWithProviders />,
      children: [
        {
          path: '',
          element: <PublicRoute />,
          children: [
            { path: '', element: <Auth /> },
            { path: 'login', element: <Auth /> },
            { path: 'forgot-password', element: <ForgotPassword /> },
            { path: 'users/activate/:token', element: <Activate /> },
            { path: 'reset-password/:token', element: <ResetPassword /> },
            {
              path: 'component-demo',
              element: <>{VITE_INCLUDE_DEMO === 'true' && <ComponentDemo />}</>,
            },
          ],
        },
        {
          path: '',
          element: <PrivateRoute />,
          children: [
            { path: 'projects', element: <Projects /> },
            {
              path: 'project',
              children: [
                { path: 'new', element: <ProjectEdit /> },
                {
                  path: ':pid',
                  element: (
                    <RolesProjectRoute
                      roles={[userRole.admin, userRole.author, userRole.member]}
                    />
                  ),
                  children: [
                    {
                      path: 'edit',
                      element: <ProjectEdit />,
                    },
                    {
                      path: '',
                      element: <ProjectLayout />,
                      children: [
                        {
                          path: 'tuning',
                          element: <Tuning />,
                          children: [
                            { path: '', element: <TuningGeneral /> },
                            {
                              path: ':dataset_id',
                              element: <TuningDataset />,
                            },
                          ],
                        },
                        { path: 'billing', element: <Billing /> },
                        { path: 'go-live', element: <GoLive /> },
                        {
                          path: 'usage',
                          element: <Usage />,
                        },
                        {
                          path: 'dataset',
                          element: <HOCDataSet />,
                        },
                        {
                          path: 'dataset',
                          element: <Dataset />,
                          children: [
                            {
                              path: 'create',
                              element: <CreateDatasetContainer />,
                            },
                            {
                              path: ':id',
                              element: <UpdateDatasetContainer />,
                            },
                          ],
                        },
                        {
                          path: 'data-warehouse',
                          element: <HOCDataWarehouse />,
                        },
                        {
                          path: 'data-warehouse',
                          element: <DataWarehouse />,
                          children: [
                            {
                              path: 'create',
                              element: <DataWarehouseCreateContainer />,
                            },
                            {
                              path: ':id',
                              element: <DataWarehouseUpdateContainer />,
                            },
                          ],
                        },
                        {
                          path: 'users',
                          element: <UsersChannel />,
                          children: [
                            { path: '', element: <TeramotChannel /> },
                            { path: 'teramot', element: <TeramotChannel /> },
                            { path: 'whatsapp', element: <WhatsappChannel /> },
                            {
                              path: 'quicksight',
                              element: <AdminRoute />,
                              children: [
                                {
                                  path: '',
                                  element: <QuicksightChannel />,
                                },
                              ],
                            },
                          ],
                        },
                        { path: 'question', element: <Question /> },
                        {
                          path: 'settings',
                          element: <ProjectEdit />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            { path: 'user-setting', element: <UserSetting /> },
            {
              path: 'project/:id',
              element: <Outlet />,
              children: [
                {
                  path: 'billing-congratulation',
                  element: <BillingCongratulation />,
                },
                { path: 'billing-qr', element: <BillingQr /> },
              ],
            },
            { path: 'conversation/:pid', element: <Conversation /> },
          ],
        },
        {
          path: 'admin',
          element: <AdminRoute />,
        },
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
  ])

  return <RouterProvider router={router} />
}

export default AppWithRouterProvider
