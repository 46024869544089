import { Header } from '@/components/layout'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { languageKey } from '@/constants/common'
import { useTrans } from '@/hooks/useTranslation'

import { ProjectEditProvider } from './projectEdit.context'
import useProjectEdit from './projectEdit.hook'
import ProjectMembers from './projectMembers/projectMembers'
import ProjectThumbnail from './projectThumbnail/projectThumbnail'

const ProjectEdit = () => {
  const { t } = useTrans()
  const {
    isCreateNewProject,
    isSelectedPlayground,
    form,
    onSubmit,
    isLoading,
  } = useProjectEdit()

  return (
    <div className="h-dvh w-screen flex flex-col">
      <Header />
      <div className="w-full container">
        {isCreateNewProject && (
          <p className="h2 mb-4">{t('setUpNewProject')}</p>
        )}
        <Form {...form}>
          <form className="w-full md:w-3/4" onSubmit={onSubmit}>
            <ProjectThumbnail />
            <div className="w-full flex flex-col gap-4">
              <div className="flex flex-col space-y-1.5">
                <FormLabel
                  className="font-semibold"
                  htmlFor="name"
                  requiredField
                >
                  {t('name')}
                </FormLabel>
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input placeholder={t('name')} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex flex-col space-y-1.5">
                <FormLabel className="font-semibold" htmlFor="description">
                  {t('Description')}
                </FormLabel>
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          placeholder={t('description')}
                          type="text"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex flex-col space-y-1.5">
                <FormLabel className="font-semibold" requiredField>
                  {t('language')}
                </FormLabel>
                <FormField
                  control={form.control}
                  name="language"
                  render={({ field }) => (
                    <FormItem>
                      <Select
                        {...field}
                        defaultValue={field.value}
                        disabled={isSelectedPlayground || !isCreateNewProject}
                        onValueChange={field.onChange}
                      >
                        <FormControl className="h-11">
                          <SelectTrigger
                            className="w-full"
                            {...field}
                            value={field.value}
                          >
                            <SelectValue placeholder={t('selectLanguage')} />
                          </SelectTrigger>
                        </FormControl>

                        <SelectContent>
                          <SelectGroup>
                            {Object.entries(languageKey).map(([key, value]) => (
                              <SelectItem key={key} value={key}>
                                {value}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex flex-col space-y-1.5">
                <Label className="font-semibold">{t('member')}</Label>
                <FormField
                  control={form.control}
                  name="email"
                  render={() => (
                    <FormItem>
                      <FormControl>
                        <ProjectMembers />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="w-full flex justify-end mt-16">
              <Button disabled={isLoading} variant="secondary">
                {t('saveChanges')}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  )
}

const ProjectEditContainer = () => {
  return (
    <ProjectEditProvider>
      <ProjectEdit />
    </ProjectEditProvider>
  )
}

export default ProjectEditContainer
