import { Button } from '@/components/ui/button'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useTrans } from '@/hooks/useTranslation'
import Snowflake from '@/pages/dataset/datasetCreate/updateConnection/snowflake/snowflake'
import {
  TableFormFactory,
  TableFormFactoryProvider,
} from '@/pages/dataset/tableFormFactory'

import { DB_CONNECT_TYPE } from '../../../dataset.constant'
import { useChangeStepUpdateDatabase } from '../updateDatabase.hook'
import BigQuery from './bigQuery/bigQuery'
import DatabaseSQL from './databaseSQL/databaseSQL'
import Salesforce from './salesforce/salesforce'
import useDatabaseConnection from './updateDatabaseConnect.hook'
import { UpdateDatabaseConnectionProps } from './updateDatabaseConnect.props'

const UpdateDatabaseConnection = (props: UpdateDatabaseConnectionProps) => {
  const { form } = props
  const { t } = useTrans()
  const { handleViewUpdate } = useChangeStepUpdateDatabase()

  const { isShowTables, currentConnectType, handleConnectionDatabase } =
    useDatabaseConnection(props)

  if (isShowTables) {
    return (
      <div className="w-full p-6 border border-slate-200 rounded-md mt-8 overflow-y-auto">
        <TableFormFactoryProvider form={form}>
          <FormField
            control={form.control}
            name="tables"
            render={({ field }) => {
              if (!field.value || !field.value.length) return <div></div>
              const _ = field.value

              return <TableFormFactory list={_} />
            }}
          />
        </TableFormFactoryProvider>
      </div>
    )
  }

  return (
    <div className="w-full">
      <div className="flex justify-between">
        {/* <label className="flex items-center gap-2 base1 font-medium cursor-pointer">
          <Checkbox />
          Use SHH Tunnel
        </label> */}

        <div>
          <Button
            className="!border-none !shadow-none !bg-transparent !text-secondary-1"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleViewUpdate()
            }}
            variant={'outline'}
          >
            {t('viewUpdate')}
          </Button>
          <Button
            className="!border-none !shadow-none !bg-transparent !text-secondary-1"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleConnectionDatabase()
            }}
            variant={'outline'}
          >
            Connect
          </Button>
        </div>
      </div>
      <div className="w-full p-6 border border-slate-200 rounded-md mt-8 flex flex-col gap-4">
        <FormField
          control={form.control}
          name="dbConfig.dbType"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold" requiredField>
                {t('dbType')}
              </FormLabel>
              <Select
                defaultValue={field?.value ?? 'postgresql'}
                onValueChange={field.onChange}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Please select a type of database" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value={DB_CONNECT_TYPE.MYSQL}>MySQL</SelectItem>
                  <SelectItem value={DB_CONNECT_TYPE.POSTGRES_SQL}>
                    PostgreSQL
                  </SelectItem>
                  <SelectItem value={DB_CONNECT_TYPE.BIG_QUERY}>
                    Big-query
                  </SelectItem>
                  <SelectItem value={DB_CONNECT_TYPE.SALESFORCE}>
                    Salesforce
                  </SelectItem>
                  <SelectItem value={DB_CONNECT_TYPE.SNOWFLAKE}>
                    Snowflake
                  </SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        {currentConnectType === DB_CONNECT_TYPE.BIG_QUERY ? (
          <BigQuery form={form} />
        ) : null}
        {currentConnectType === DB_CONNECT_TYPE.SALESFORCE ? (
          <Salesforce form={form} />
        ) : null}
        {currentConnectType === DB_CONNECT_TYPE.SNOWFLAKE ? (
          <Snowflake form={form} />
        ) : null}
        {currentConnectType !== DB_CONNECT_TYPE.SALESFORCE &&
        currentConnectType !== DB_CONNECT_TYPE.BIG_QUERY &&
        currentConnectType !== DB_CONNECT_TYPE.SNOWFLAKE ? (
          <DatabaseSQL form={form} />
        ) : null}
      </div>
    </div>
  )
}

export default UpdateDatabaseConnection
