import React, { useEffect } from 'react'

import { BREAKPOINTS } from '@/constants/common'
import {
  clearCheckedWarehouses,
  setCheckedWarehouses,
  setConfiguration,
} from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { useGetCurrentProject } from '@/hooks/useGetCurrentProject/useGetCurrentProject'
import { useRouter } from '@/hooks/useRouter'
import { useWindowSize } from '@/hooks/useWindowSize'

const useSubSidebar = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { width } = useWindowSize()
  const dispatch = useAppDispatch()
  const { location } = useRouter()

  const { warehouse, project, pid, configuration } = useGetCurrentProject()

  const selectedReports = project?.configuration?.selectedReports

  const isMediumScreens = width >= BREAKPOINTS.MD

  useEffect(() => {
    dispatch(clearCheckedWarehouses())
    if (location.pathname.includes('tuning')) {
      if (selectedReports) {
        warehouse.forEach((wh) => {
          const isChecked = !!selectedReports[wh.id!]
          dispatch(setCheckedWarehouses(wh.id!, isChecked))
        })
      } else if (configuration && Object.keys(configuration).length > 0) {
        const localCheckedWarehouses: Record<string, boolean> = {}

        warehouse.forEach((wh) => {
          localCheckedWarehouses[wh.id!] = true
          dispatch(setCheckedWarehouses(wh.id!, true))
        })

        const _configuration = {
          ...configuration,
          selectedReports: localCheckedWarehouses,
          knowledge: configuration.knowledge ?? {},
          knowledgeGeneral: configuration.knowledgeGeneral ?? [],
        }

        dispatch(setConfiguration(pid, { ..._configuration }))
      } else {
        warehouse.forEach((wh) => {
          dispatch(setCheckedWarehouses(wh.id!, true))
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReports, configuration])

  const handleToggle = () => {
    if (isMediumScreens) return
    setIsOpen(!isOpen)
  }

  return {
    isOpen,
    handleToggle,
    isMediumScreens,
  }
}

export default useSubSidebar
