import { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { setTableFile } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import { useWarningBeforeLeave } from '@/hooks/useValidateCreateDatasetBeforeLeave'
import { ANY } from '@/types'

import { useDatasetCreateContext } from '../datasetCreate.context'
import { AllowUpdateSpreadsheet } from './updateSpreadsheet.constant'
import {
  IUseABSUpdateSpreadsheetProps,
  UseUpdateSpreadsheetLeavePageProps,
} from './updateSpreadsheet.props'

export const useUpdateSpreadsheetFile = (
  props: IUseABSUpdateSpreadsheetProps,
) => {
  const { params } = useRouter()
  const { t } = useTrans()
  const pid = params.pid
  const { form } = props
  const { dataset, handleUpdateCurrentDataset, handleNextStep } =
    useDatasetCreateContext()
  const [file, setFile] = useState<File | undefined>(undefined)
  const [files, setFiles] = useState<FileList | null>(null)
  const dispatch = useAppDispatch()

  const onChangeUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _file = e.target.files?.[0]
    const fileType = _file?.type

    if (
      !fileType ||
      !AllowUpdateSpreadsheet.some((type) => fileType.includes(type))
    ) {
      toast.error(t('inValidAllowUploadFileSpreadsheet'))
      return
    }

    if (_file) {
      // set value check is upload file
      form.setValue('isUploadFile', _file?.name ? _file?.name : 'false')
      setFile(_file)
      return
    }
  }

  const onChangeUploadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _files = e.target.files
    const listFiles = Array.from(_files as FileList)
    for (const file of listFiles) {
      const fileType = file?.type
      if (
        !fileType ||
        !AllowUpdateSpreadsheet.some((type) => fileType.includes(type))
      ) {
        toast.error(t('inValidAllowUploadFileSpreadsheet'))
        return
      }
    }

    if (_files) {
      // set value check is upload file
      form.setValue('isUploadFile', true)
      setFiles(_files)
      return
    }
  }

  const uploadFileToServer = useCallback(
    async (files: FileList) => {
      if (!pid) return

      const datasetName = dataset?.name ?? ''
      const datasetDescription = dataset?.description ?? ''

      const data = new FormData()
      for (const file of Array.from(files)) data.append('files', file)
      data.append('fileName', datasetName)
      data.append('fileDescription', datasetDescription)
      const promise = dispatch(setTableFile(pid, data, () => {}))
      toast.promise(promise, {
        pending: t('processingData'),
        success: t('tableAddToProject'),
        error: t('errorLoadingFiles'),
      })

      promise.then((project) => {
        handleUpdateCurrentDataset({
          meta: project as Record<string, unknown>,
        })
        handleNextStep()
      })
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, pid],
  )

  useEffect(() => {
    if (!files) return
    uploadFileToServer(files)
  }, [files, uploadFileToServer])

  const overrideEventDefaults = (event: ANY) => {
    event.preventDefault()
    event.stopPropagation()
  }
  const handleDragAndDropFiles = (event: ANY) => {
    overrideEventDefaults(event)
    if (!event.dataTransfer) return
    handleFiles(event.dataTransfer.files)
  }
  const handleFiles = (fileList: ANY) => {
    if (fileList) {
      const files = Array.from(fileList)
      const _file = files[0] as File
      const fileType = _file?.type

      if (
        !fileType ||
        !AllowUpdateSpreadsheet.some((type) => fileType.includes(type))
      ) {
        toast.error(t('inValidAllowUploadFileSpreadsheet'))
        return
      }

      setFile(_file)
      setFiles(fileList)
    }
  }

  return {
    form,
    pid,
    file,
    handleDragAndDropFiles,
    overrideEventDefaults,
    onChangeUploadFile,
    handleUpdateCurrentDataset,
    onChangeUploadFiles,
  }
}

export const useUpdateSpreadsheetOnlineLink = (
  props: IUseABSUpdateSpreadsheetProps,
) => {
  const { form } = props
  const { params } = useRouter()
  const pid = params.pid
  const { t } = useTrans()
  const { dataset, handleUpdateCurrentDataset, handleNextStep } =
    useDatasetCreateContext()
  const oldLinkUrl = useRef<string | null>(null)
  const dispatch = useAppDispatch()
  const handleUploadWithLink = (e: ANY) => {
    e.preventDefault()
    e.stopPropagation()
    const linkUrl = form.getValues('fileLink')

    if (!linkUrl) return
    if (linkUrl === oldLinkUrl.current) return
    oldLinkUrl.current = linkUrl
    const datasetName = dataset?.name ?? ''
    const datasetDescription = dataset?.description ?? ''

    const meta = {
      linkUrl,
      fileName: datasetName,
      fileDescription: datasetDescription,
    }
    const promise = dispatch(setTableFile(pid!, meta, () => {}))
    toast.promise(promise, {
      pending: t('processingData'),
      success: t('tableAddToProject'),
      error: t('errorLoadingFiles'),
    })

    promise.then((project) => {
      handleUpdateCurrentDataset({
        meta: project as Record<string, unknown>,
      })
      handleNextStep()
    })
  }

  return {
    handleUploadWithLink,
  }
}

export const useUpdateSpreadsheetLeavePage = (
  props: UseUpdateSpreadsheetLeavePageProps,
) => {
  const { setStep, setCurrentDataset } = useDatasetCreateContext()

  const { form, meta } = props

  const validate = () => {
    const isUploadFile = form.getValues('isUploadFile')
    return isUploadFile || meta?.name || meta?.description || meta?.type
  }

  const { blocker } = useWarningBeforeLeave({
    validate,
    setStep,
    setCurrentDataset,
  })

  return { blocker }
}
