import dayjs from 'dayjs'

import { isAuth } from '@/helpers/auth'
import { useTrans } from '@/hooks/useTranslation'

import { ExtraDataETLProps } from './extraDataETL.props'

const ExtraDataETL = (props: ExtraDataETLProps) => {
  const { id, status, updatedAt } = props
  const { t } = useTrans()
  const { role } = isAuth()

  return (
    <div className="space-y-2">
      {(role === 'admin' || role === 'teramot') && (
        <p className="h5 text-text-gray">
          {t('id')}: {id ?? t('none').toUpperCase()}
        </p>
      )}
      <p className="h5 text-text-gray">
        {t('status')}: {status ?? t('notRun').toUpperCase()}
      </p>
      <p className="h5 text-text-gray">
        {t('updatedAt')}:{' '}
        {dayjs(updatedAt).format('DD-MM-YYYY HH:mm') ??
          t('notRun').toUpperCase()}
      </p>
    </div>
  )
}

export default ExtraDataETL
