import { useMemo } from 'react'

import { useRouter } from '@/hooks/useRouter'
import { IProjectConfigurationData } from '@/types'

import { useAppSelector } from '..'

export const useGetCurrentProject = () => {
  const { params } = useRouter()
  const pid = params.pid!

  const projects = useAppSelector((state) => state.project.projects)

  return useMemo(() => {
    const project = projects?.[pid] ?? {}
    const configuration =
      project?.configuration ?? ({} as IProjectConfigurationData)
    const botName = configuration.botName
    const isEmptyProject = Boolean(project)
    const warehouse = project?.warehouse ?? []
    const dataset = project?.inputData ?? {}
    const processedData = project?.processedData ?? []

    return {
      pid,
      isEmptyProject,
      project,
      configuration,
      warehouse,
      dataset,
      processedData,
      botName,
    }
  }, [projects, pid])
}
