import { createContext, useCallback, useContext, useRef, useState } from 'react'

import { useRouter } from '@/hooks/useRouter'

import { CREATE_DATASET_STEP, DATASET_TYPE } from './datasetCreate.constant'
import {
  getNextCreateDatasetFlow,
  isEndStepFlow,
  IStepMap,
} from './datasetCreate.helper'
import {
  IDataSet,
  IDatasetCreateContext,
  IDatasetCreateProvider,
} from './datasetCreate.props'

export const DatasetCreateContext = createContext<IDatasetCreateContext | null>(
  null,
)

export const DatasetCreateProvider = ({ children }: IDatasetCreateProvider) => {
  const { params } = useRouter()
  const pid = params.pid as string

  const [currentStep, setStep] = useState<CREATE_DATASET_STEP>(
    CREATE_DATASET_STEP.NAME,
  )
  const historyStep = useRef<CREATE_DATASET_STEP[]>([])

  const [tablesDatabase, setTableDatabase] = useState<
    Array<Record<string, unknown>>
  >([])

  const [currentDataset, setCurrentDataset] = useState<IDataSet>()
  const isEndFlow = isEndStepFlow(currentStep as IStepMap)

  const handleNextStep = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (type?: string) => {
      const nextStep = getNextCreateDatasetFlow(
        currentStep as IStepMap,
        (type ?? currentDataset?.type) as DATASET_TYPE,
      )
      if (
        [CREATE_DATASET_STEP.COLUMN].includes(currentStep) &&
        nextStep === CREATE_DATASET_STEP.NAME
      ) {
        setCurrentDataset(undefined)
      }
      historyStep.current.push(currentStep)
      if (nextStep === CREATE_DATASET_STEP.NAME) {
        historyStep.current = []
      }
      setStep(nextStep as CREATE_DATASET_STEP)
    },
    [currentStep],
  )

  const handlePreStep = useCallback(() => {
    const preStep = historyStep.current.pop()

    setStep(preStep as CREATE_DATASET_STEP)
  }, [currentStep])

  const handleUpdateCurrentDataset = (payload: Record<string, unknown>) => {
    setCurrentDataset((prev) => {
      return {
        ...(prev as IDataSet),
        ...payload,
        meta: {
          ...(prev?.meta ?? {}),
          ...(payload?.meta ?? {}),
        },
      }
    })
  }

  return (
    <DatasetCreateContext.Provider
      value={{
        pid,
        tablesDatabase,
        isEndFlow,
        dataset: currentDataset as IDataSet,
        setCurrentDataset,
        currentStep,
        setStep,
        setTableDatabase,
        handleNextStep,
        handlePreStep,
        handleUpdateCurrentDataset,
      }}
    >
      {children}
    </DatasetCreateContext.Provider>
  )
}

export const useDatasetCreateContext = () => {
  const ctx = useContext(DatasetCreateContext)

  if (!ctx)
    throw new Error(
      'useDatasetCreate must be used within a DatasetCreateProvider',
    )

  return {
    pid: ctx.pid,
    tablesDatabase: ctx.tablesDatabase,
    isEndFlow: ctx.isEndFlow,
    dataset: ctx.dataset,
    setCurrentDataset: ctx.setCurrentDataset,
    currentStep: ctx.currentStep,
    setStep: ctx.setStep,
    setTableDatabase: ctx.setTableDatabase,
    handleNextStep: ctx.handleNextStep,
    handlePreStep: ctx.handlePreStep,
    handleUpdateCurrentDataset: ctx.handleUpdateCurrentDataset,
  }
}
