import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { setConfiguration } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { useTrans } from '@/hooks/useTranslation'
import { IProject, IProjectConfigurationData } from '@/types'

import useTuning from '../../tuning.hook'

interface IProps {
  pid: string
  configuration: IProjectConfigurationData
  project: IProject
}
export const useKnowledgeList = (props: IProps) => {
  const { configuration, pid, project } = props
  const [isCreate, setIsCreate] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const { checkedWarehouses } = useTuning()

  const { t } = useTrans()
  const dispatch = useAppDispatch()
  const toggleCreate = () => {
    setIsCreate((old) => !old)
  }

  useEffect(() => {
    const hasCheckedWarehouse = Object.values(checkedWarehouses).some(
      (value) => value === true,
    )
    setIsDisabled(!hasCheckedWarehouse)
  }, [checkedWarehouses])

  const handleSaveKnowledge = () => {
    const { botName, lang } = configuration

    const warehouse = project.warehouse

    const oldKnowledge = configuration?.knowledge ?? {}
    const allKnowledges = warehouse!.reduce((t, v) => {
      const key = v.id!
      if (key && !oldKnowledge[key]) {
        return { ...t, [key]: [] }
      }
      return { ...t, [key]: oldKnowledge[key] }
    }, {})

    const oldKnowledgeGeneral = configuration?.knowledgeGeneral ?? []

    const _configuration = {
      botName,
      lang,
      knowledge: allKnowledges,
      knowledgeGeneral: oldKnowledgeGeneral,
      selectedReports: checkedWarehouses,
    }

    const promise = dispatch(setConfiguration(pid, { ..._configuration }))
    toast.promise(promise, {
      pending: t('processingConfiguration'),
      success: t('configurationSetSuccessfully'),
      error: t('errorSettingConfiguringBot'),
    })
    promise.catch((e) => {
      if (e?.key) toast.error(t(e.key))
    })
  }

  return {
    dispatch,
    isCreate,
    toggleCreate,
    handleSaveKnowledge,
    isDisabled,
  }
}
