import { setCheckedWarehouses } from '@/features/project/projectSlice'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { useGetCurrentProject } from '@/hooks/useGetCurrentProject/useGetCurrentProject'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import { ANY } from '@/types'

const useTuning = () => {
  const { t } = useTrans()

  const { params, navigate } = useRouter()
  const { pid } = params
  const dispatch = useAppDispatch()
  const checkedWarehouses = useAppSelector(
    (state) => state.project.checkedWarehouses,
  )
  const { warehouse, project, configuration, botName } = useGetCurrentProject()

  const isProjectValid = project && Object.keys(project).length > 0

  const subSidebarLinks: Array<ANY> = warehouse.reduce(
    (t, wh) => {
      t.push({
        key: wh.id!,
        label: wh.name,
        onClick: () => navigate(`/project/${pid}/tuning/${wh.id!}`),
      })
      return t
    },
    [
      {
        key: '',
        label: 'General',
        onClick: () => navigate(`/project/${pid}/tuning`),
      },
    ],
  )

  const handleCheckboxChange = (key: string) => {
    const keysCount = Object.keys(checkedWarehouses).length
    if (keysCount === 1) {
      dispatch(setCheckedWarehouses(key, true))
      return
    }

    const currentValue = checkedWarehouses[key]
    dispatch(setCheckedWarehouses(key, !currentValue))
  }

  return {
    t,
    subSidebarLinks: subSidebarLinks,
    navigate,
    warehouse,
    handleCheckboxChange,
    checkedWarehouses,
    project,
    configuration,
    botName,
    isProjectValid,
  }
}

export default useTuning
