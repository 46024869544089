import { useEffect, useRef } from 'react'

import { setCurrentProject } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { useRouter } from '@/hooks/useRouter'

const useProjectLayout = () => {
  const dispatch = useAppDispatch()
  const { params } = useRouter()
  const { pid } = params

  const refEffectOne = useRef({
    pid: '',
    effect: false,
  })

  useEffect(() => {
    if (!pid) return

    if (refEffectOne.current.pid !== pid) {
      refEffectOne.current = {
        pid,
        effect: false,
      }
    }

    if (refEffectOne.current.effect) return

    dispatch(setCurrentProject(pid))
    refEffectOne.current = {
      pid,
      effect: true,
    }
  }, [dispatch, pid])

  return {}
}

export default useProjectLayout
