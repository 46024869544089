import React from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import IcBilling from '@/components/Icon/billing.svg?react'
import IcClose from '@/components/Icon/close.svg?react'
import IcWarehouse from '@/components/Icon/database.svg?react'
import IcGoLive from '@/components/Icon/golive.svg?react'
import IcInputData from '@/components/Icon/inputData.svg?react'
import IconMenu from '@/components/Icon/menu.svg?react'
import IcToggleMenu from '@/components/Icon/toggleMenu.svg?react'
import IcTuning from '@/components/Icon/tuning.svg?react'
import IcUsage from '@/components/Icon/usage.svg?react'
import IcUsers from '@/components/Icon/users.svg?react'
import { BREAKPOINTS } from '@/constants/common'
import { useProject } from '@/contexts/projectContext'
import { useSideBar } from '@/contexts/sidebarContext'
import { deleteProject } from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { useGetProject } from '@/hooks/useGetProject/useGetProject'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'
import { useWindowSize } from '@/hooks/useWindowSize'
import { cn } from '@/lib/utils'

import Logo from '../Logo/Logo'
import Rodal from '../rodal/rodal'
import { confirmRodal } from '../rodal/rodal.helper'
import { Button } from '../ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/UserNav/dropdown-menu'
import { SideBarBody } from './components'

const Items = [
  {
    to: '/dataset',
    icon: <IcInputData />,
    label: 'inputData',
    active: false,
  },
  {
    to: '/data-warehouse',
    icon: <IcWarehouse />,
    label: 'dataWarehouse',
    active: false,
  },
  {
    to: '/tuning',
    icon: <IcTuning />,
    label: 'tuning',
    active: false,
  },
  {
    to: '/users',
    icon: <IcUsers />,
    label: 'users',
    active: false,
  },
  {
    to: '/billing',
    icon: <IcBilling />,
    label: 'billingSection',
    active: true,
  },
  {
    to: '/go-live',
    icon: <IcGoLive />,
    label: 'goLive',
    active: false,
  },
  {
    to: '/usage',
    icon: <IcUsage />,
    label: 'usage',
    active: false,
  },
]

const SideBar = () => {
  const { t } = useTrans()
  const { width } = useWindowSize()
  const { location, params, navigate } = useRouter()
  const { isOpenMenu, handleToggleMenu } = useSideBar()
  const { isNewQuestion } = useProject()
  const dispatch = useAppDispatch()

  const pid = params.pid
  const isMediumScreens = width >= BREAKPOINTS.MD

  const classContainer = cn(
    'w-full h-full md:max-w-[260px] z-20',
    'fixed top-0 left-[-100%] bg-black/[0.8]',
    'overflow-hidden transition-all',
    {
      'cursor-pointer': !isMediumScreens,
      'left-0 transition-all': isOpenMenu,
      '!left-0 relative': isMediumScreens,
    },
  )

  const { project, playground } = useGetProject({ pid: pid! })

  const goToSettingPage = () => {
    navigate('/project/' + pid + '/settings')
  }

  const handleDeleteProject = () => {
    if (!pid) return

    confirmRodal({
      children: ({ onClose }) => {
        return (
          <Rodal
            message={t('descriptionDeleteKnowledge')}
            onCancel={onClose}
            onClose={onClose}
            onConfirm={() => {
              const promise = dispatch(deleteProject({ pid }))
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              toast.promise(promise as any, {
                pending: t('deleteProjectToastPending'),
                success: t('deleteProjectToastSuccess'),
                error: t('deleteProjectToastError'),
              })

              promise.then(() => {
                navigate('/projects')
              })
            }}
            textCancel={t('deleteProjectPopupCancel')}
            textConfirm={t('deleteProjectPopupConfirm')}
            title={t('titleDeleteKnowledge')}
          />
        )
      },
    })
  }
  return (
    <React.Fragment>
      {!isMediumScreens && (
        // eslint-disable-next-line max-len
        <div className="w-full container bg-primary-1 flex justify-between sticky top-0 h-[80px] items-center z-20 shrink-0">
          <Logo />
          <Button
            className="!bg-white !rounded-[8px]"
            onClick={handleToggleMenu}
          >
            <IcToggleMenu />
          </Button>
        </div>
      )}
      <div className={classContainer} onClick={handleToggleMenu}>
        <div className="w-11/12 md:w-full h-full relative bg-primary-1">
          <div className="w-full flex justify-between md:justify-center pt-[30px] md:pt-[40px] px-8">
            <Logo />
            <Button
              className="!bg-white !rounded-[8px] block md:hidden"
              onClick={handleToggleMenu}
              size={'sm'}
            >
              <IcClose />
            </Button>
          </div>
          {/* Name Project here */}
          <div className="w-full px-8 py-2 ">
            <div className="relative mt-20 flex justify-between">
              <p className="h4 text-white text-left line-clamp-1 break-all whitespace-break-spaces cursor-default pr-2">
                {String(project?.name || playground?.name || '')}
              </p>
              <div className="relative size-8 flex-shrink-0">
                <DropdownMenu>
                  <DropdownMenuTrigger
                    asChild
                    className="focus-visible:border-none focus-visible:ring-0"
                  >
                    <Button
                      borderRadius="full"
                      className="opacity-50 hover:opacity-100 active:opacity-100"
                      style={{ height: '32px', width: '32px' }}
                      variant="secondary"
                    >
                      <IconMenu className="absolute rotate-90" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    className="w-60"
                    style={{
                      position: 'absolute',
                      left: 'calc(100% - 16px)',
                    }}
                  >
                    <DropdownMenuItem
                      className="py-1.5 px-8 cursor-pointer"
                      onClick={goToSettingPage}
                    >
                      {t('projectSettings')}
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="py-1.5 px-8 cursor-pointer"
                      onClick={handleDeleteProject}
                    >
                      <span className="text-red-600">
                        {' '}
                        {t('deleteProject')}
                      </span>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          </div>

          <SideBarBody>
            {Items.map((item, index) => {
              const to = item.to
              return (
                <Link
                  key={index}
                  onClick={(e) => {
                    e.preventDefault()

                    const typePage = location.pathname.split('/').pop()
                    if (typePage === 'question') {
                      if (isNewQuestion) {
                        confirmRodal({
                          children: ({ onClose }) => {
                            return (
                              <Rodal
                                message={t('unsavedChanges')}
                                onCancel={onClose}
                                onClose={onClose}
                                onConfirm={() => {
                                  navigate('/project/' + pid + to)
                                }}
                                title={t('warning')}
                              />
                            )
                          },
                        })
                        return
                      }
                    }
                    return navigate('/project/' + pid + to)
                  }}
                  to={'/project/' + pid + to}
                >
                  <SideBarBody.Item
                    active={location.pathname.includes(to)}
                    icon={item.icon}
                    label={t(item.label)}
                  ></SideBarBody.Item>
                </Link>
              )
            })}
          </SideBarBody>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SideBar
