import { toast } from 'react-toastify'

import { confirmRodal } from '@/components/rodal'
import Rodal from '@/components/rodal/rodal'
import {
  removeKnowledge,
  setConfiguration,
} from '@/features/project/projectSlice'
import { useAppDispatch } from '@/hooks'
import { useGetCurrentProject } from '@/hooks/useGetCurrentProject/useGetCurrentProject'
import { useToggle } from '@/hooks/useToggle'
import { useTrans } from '@/hooks/useTranslation'
import { useWarehouseStatusProcessing } from '@/hooks/useWarehouseStatusProcessed/useWarehouseStatusProcessed'

import useTuning from '../../tuning.hook'
import { useKnowledgeList as useKnowledgeGeneralList } from '../knowledgeGeneralList/knowledgeGeneralList.hook'

export const useKnowledgeList = () => {
  const { configuration, warehouse, pid, project } = useGetCurrentProject()
  const { isWarehouseAllProcessing } = useWarehouseStatusProcessing()
  const { toggle, handleToggle } = useToggle()
  const { checkedWarehouses } = useTuning()
  const { isDisabled } = useKnowledgeGeneralList({
    pid,
    configuration,
    project,
  })

  const dispatch = useAppDispatch()
  const { t } = useTrans()

  const handleSaveKnowledge = () => {
    if (isWarehouseAllProcessing) {
      return confirmRodal({
        children: ({ onClose }) => {
          return (
            <Rodal
              message={t('warehouseAllProcessingMessage')}
              onClose={onClose}
              onConfirm={() => {
                onClose()
              }}
              textConfirm={t('warehouseTextConfirm')}
              title={t('warehouseAllProcessing')}
            />
          )
        },
      })
    }
    const { botName } = configuration

    const oldKnowledge = configuration?.knowledge ?? {}
    const newKnowledge = warehouse.reduce((t, wh) => {
      const key = wh.id!
      if (!oldKnowledge[key]) {
        return { ...t, [key]: [] }
      }
      return { ...t, [key]: oldKnowledge[key] }
    }, {})

    const oldKnowledgeGeneral = configuration?.knowledgeGeneral ?? []

    const _configuration = {
      botName,
      lang: configuration?.lang ?? 'en',
      knowledge: newKnowledge,
      knowledgeGeneral: oldKnowledgeGeneral,
      selectedReports: checkedWarehouses,
    }

    const promise = dispatch(setConfiguration(pid, { ..._configuration }))
    toast.promise(promise, {
      pending: t('processingConfiguration'),
      success: t('configurationSetSuccessfully'),
      error: t('errorSettingConfiguringBot'),
    })
    promise.catch((e) => {
      if (e?.key) toast.error(t(e.key))
    })
  }

  const handleRemoveKnowledge = ({
    index,
    datasetId,
  }: {
    index: number
    datasetId: string
  }) => {
    dispatch(removeKnowledge({ pid, index, datasetId: datasetId }))
  }
  return {
    pid,
    isCreate: toggle,
    toggleCreate: handleToggle,
    handleSaveKnowledge,
    handleRemoveKnowledge,
    isDisabled,
  }
}
