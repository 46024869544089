import { icQuicksightProps } from './icQuicksight.props'

const icQuicksight = (props: icQuicksightProps) => {
  return (
    <div
      className={props.className}
      style={{
        width: props.width ?? 40,
        height: props.height ?? 40,
      }}
    >
      <img
        alt="IC Quicksight"
        className="w-full h-full object-contain"
        src="/ic_quicksight.png"
      />
    </div>
  )
}

export default icQuicksight
