import { useEffect } from 'react'

import IcQuicksight from '@/components/Icon/icQuicksight/icQuicksight'
import IcSlack from '@/components/Icon/icSlack/icSlack'
import IcTeams from '@/components/Icon/icTeams/icTeams'
import IcTeramot from '@/components/Icon/icTeramot/icTeramot'
import IcWhatsapp from '@/components/Icon/icWhatsapp/icWhatsapp'
import { isAuth } from '@/helpers/auth'
import { useRouter } from '@/hooks/useRouter'

const useUsersChannel = () => {
  const { params, location, navigate } = useRouter()
  const { pid } = params
  const { role } = isAuth()

  const subSidebarLinks = [
    {
      key: 'teramot',
      label: 'Teramot',
      onClick: () => navigate(`/project/${pid}/users/teramot`),
      icon: <IcTeramot className="shrink-0" height={20} width={20} />,
    },
    {
      key: 'whatsapp',
      label: 'Whatsapp',
      onClick: () => navigate(`/project/${pid}/users/whatsapp`),
      icon: <IcWhatsapp className="shrink-0" height={20} width={20} />,
    },
    {
      key: 'quicksight',
      label: 'QuickSight',
      onClick: () => navigate(`/project/${pid}/users/quicksight`),
      disable: role !== 'admin',
      icon: <IcQuicksight className="shrink-0" height={20} width={20} />,
    },
    {
      key: 'teams',
      label: 'Teams',
      onClick: () => {},
      disable: true,
      icon: <IcTeams className="shrink-0" height={20} width={20} />,
    },
    {
      key: 'slack',
      label: 'Slack',
      onClick: () => {},
      disable: true,
      icon: <IcSlack className="shrink-0" height={20} width={20} />,
    },
  ]

  // Redirect to teramot channel if the channel is not found
  useEffect(() => {
    const pathNames = location.pathname.split('/')
    const channel = pathNames.pop()
    const subSidebarLink = subSidebarLinks.find((item) => item.key === channel)
    if (!subSidebarLink) {
      navigate(`/project/${pid}/users/teramot`)
    }
  }, [location.pathname])

  return { subSidebarLinks }
}

export default useUsersChannel
