import 'react-confirm-alert/src/react-confirm-alert.css'
import './Project.css'

// import IcFolder from '@/assets/IcFolder.tsx'
import IcPlusWhite from '@/assets/IcPlusWhite'
import Footer from '@/components/Footer/footer'
import { Header } from '@/components/layout'
import MainCard from '@/components/MainCard/mainCard'
import Spin from '@/components/spin/spin'
import { Empty } from '@/components/ui'
import { Button } from '@/components/ui/button'
import { useAuthorProject } from '@/hooks/useIsAuthProject/useAuthorProject'
import { useTrans } from '@/hooks/useTranslation'

import { useProjectsPage } from './Projects.hook'

const Projects = () => {
  const {
    isLoading,
    listProject,
    isEmptyProject,
    onCreateNewProject,
    getProjectThumbnail,
    playgrounds,
    getAllPlaygrounds,
    onNewPlaygroundClick,
  } = useProjectsPage()
  const { isAuthorProject } = useAuthorProject()
  const { t } = useTrans()

  return (
    <div className="h-screen w-screen flex flex-col">
      <Header />

      <div className="container w-full grow flex flex-col">
        {/* CREATE A NEW PROJECT */}
        <div className="createProjectRow  ">
          <p className="text-h1">{t('goodMorning')}</p>
          <div className="flex gap-2">
            <Button onClick={onCreateNewProject} variant={'primary'}>
              <div className="mr-2.5 h-4 w-4">
                <IcPlusWhite />
              </div>
              {t('createProject')}
            </Button>
            <Button onClick={getAllPlaygrounds} variant={'secondary'}>
              {t('usePlayground')}
            </Button>
          </div>
        </div>

        {/* PROJECT GRID */}
        {isLoading ? (
          <div className="grow flex justify-center items-center">
            <Spin />
          </div>
        ) : isEmptyProject ? (
          <div className="projectEmpty grow !gap-4 w-full">
            {playgrounds.length > 0 && (
              <>
                <p className="mt-2 text-xl font-semibold">{t('playgrounds')}</p>
                <div className="flex justify-center gap-4 mt-2">
                  {playgrounds.map((playground) => {
                    return (
                      <Button
                        className="text-lg w-auto min-w-[300px]"
                        key={playground.etlID as string}
                        onClick={() =>
                          onNewPlaygroundClick(playground.etlID as string)
                        }
                      >
                        {`${playground.name}
                        ${
                          playground.lang && playground.lang === 'en'
                            ? '- english'
                            : playground.lang === 'es'
                              ? '- español'
                              : ''
                        }`}
                      </Button>
                    )
                  })}
                </div>
              </>
            )}
            <Empty>
              <div className="projectEmptyLayout">
                <div className="projectEmptyContent">
                  <h1 className="projectEmptyTitle">{t('noProjects')}</h1>
                  <p className="projectEmptyText">
                    {t('noProjectsDescription')}
                  </p>
                </div>
                <div className="flex gap-2">
                  <Button onClick={onCreateNewProject} variant={'primary'}>
                    <div className="mr-2.5 h-4 w-4">
                      <IcPlusWhite />
                    </div>
                    {t('createProject')}
                  </Button>
                  <Button onClick={getAllPlaygrounds} variant={'secondary'}>
                    {t('usePlayground')}
                  </Button>
                </div>
              </div>
            </Empty>
          </div>
        ) : (
          <div className="w-full">
            {playgrounds.length > 0 && (
              <>
                <p className="mt-8 text-xl font-semibold">{t('playgrounds')}</p>
                <div className="projectGrid grow !mt-8">
                  {playgrounds.map((playground) => {
                    return (
                      <Button
                        className="text-lg"
                        key={playground.etlID as string}
                        onClick={() =>
                          onNewPlaygroundClick(playground.etlID as string)
                        }
                      >
                        {`${playground.name}
                        ${
                          playground.lang && playground.lang === 'en'
                            ? '- english'
                            : playground.lang === 'es'
                              ? '- español'
                              : ''
                        }`}
                      </Button>
                    )
                  })}
                </div>
                {<p className="mt-16 text-xl font-semibold">{t('projects')}</p>}
              </>
            )}
            <div className="projectGrid grow !mt-8">
              {listProject.map((project) => {
                return (
                  <MainCard
                    className="h-full"
                    description={project.description}
                    imageSrc={getProjectThumbnail(
                      project.pid,
                      project.coverFile,
                    )}
                    isAuthor={isAuthorProject(project)}
                    key={project.pid}
                    pid={project.pid}
                    title={project.name}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default Projects
