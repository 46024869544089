import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { confirmRodal } from '@/components/rodal'
import Rodal from '@/components/rodal/rodal'
import {
  createQuickSightUser,
  deleteQuickSightUser,
  setCurrentProject,
  setOutputChannels,
} from '@/features/project/projectSlice'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { useGetProject } from '@/hooks/useGetProject/useGetProject'
import { useRouter } from '@/hooks/useRouter'
import { useTrans } from '@/hooks/useTranslation'

import { CHANEL_TYPE } from '../usersChannel.constant'
import { useQuicksightChannelValidate } from './quicksightChannel.validate'

const useQuicksightChannel = () => {
  const dispatch = useAppDispatch()
  const { t } = useTrans()
  const { params } = useRouter()
  const pid = params.pid!

  const { schema } = useQuicksightChannelValidate()
  const { project } = useGetProject({ pid })
  const savedEmails: Record<string, string>[] =
    project.outputChannels?.[CHANEL_TYPE.QUICKSIGHT]?.emails
  const [initialEmails, setInitialEmails] = useState<
    Array<Record<string, string>>
  >(project.outputChannels?.[CHANEL_TYPE.QUICKSIGHT]?.emails || [])
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)

  const form = useForm({
    defaultValues: { emails: [] },
    resolver: yupResolver(schema),
  })

  const quicksightData = useAppSelector((state) => state.project.quickSightData)

  const isActivateQuicksight = form.watch('activateQuicksight')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const emails = form.watch('emails') || []
  const outputChannelString = useMemo(
    () => JSON.stringify(project?.outputChannels ?? {}),
    [project?.outputChannels],
  )

  useEffect(() => {
    if (savedEmails) setInitialEmails(savedEmails)
  }, [savedEmails])

  useEffect(() => {
    const currentEmails = emails.map((item) => item.email)
    const initialEmailsArray = initialEmails.map((item) => item.email)
    const isDifferent =
      initialEmailsArray.length !== currentEmails.length ||
      !initialEmailsArray.every((email) => currentEmails.includes(email))
    setIsSaveDisabled(!isDifferent)
  }, [emails, initialEmails])

  useEffect(() => {
    const outputChannels = JSON.parse(outputChannelString)
    const outputChannel = outputChannels[CHANEL_TYPE.QUICKSIGHT]
    if (!outputChannel) return

    form.setValue(
      'activateQuicksight',
      outputChannel?.activateQuicksight || false,
    )
    form.setValue('emails', outputChannel?.emails || [])
  }, [form, outputChannelString])

  const onSubmit = form.handleSubmit(
    async (payload: Record<string, unknown>) => {
      const meta = {
        activateQuicksight: payload?.activateQuicksight ?? false,
        emails: payload.emails ?? [],
        type: CHANEL_TYPE.QUICKSIGHT,
      }

      const { etlID } = project

      const currentEmails = emails.map((item) => item.email)

      const newEmails = currentEmails.filter(
        (email) => !initialEmails.some((item) => item.email === email),
      )

      const removedEmails = initialEmails.filter(
        (item) => !currentEmails.includes(item.email),
      )

      const dataToAdd = newEmails.map((email) => {
        const emailObj = emails.find((e) => e.email === email)
        return {
          username: emailObj?.name || '',
          email: email,
        }
      })

      // Extraer `username` del objeto correspondiente
      const dataToRemove = removedEmails.map((item) => ({
        username: item.name,
      }))

      const promises = [
        dispatch(setOutputChannels(pid, meta)),
        ...(dataToAdd.length > 0
          ? [dispatch(createQuickSightUser(pid, etlID!, dataToAdd))]
          : []),
        ...(dataToRemove.length > 0
          ? [dispatch(deleteQuickSightUser(pid, etlID!, dataToRemove))]
          : []),
      ]

      toast
        .promise(Promise.all(promises), {
          pending: t('processingOutputChannels'),
          success: t('outputChannelsAddedSuccessfully'),
          error: t('errorSettingOutputChannels'),
        })
        .then(() => {
          dispatch(setCurrentProject(pid))
          setInitialEmails(
            emails.map((item) => ({
              email: item.email,
              username: item.name,
            })),
          )
          setIsSaveDisabled(true)
        })
    },
  )

  /**
   *
   * @param payload
   * @returns false if email name already exist
   * @returns true if email name not exist
   */
  const handleAddEmail = (payload: Record<string, string>) => {
    const { email, name } = payload

    const emails = form.getValues('emails') ?? []
    const isExist = emails.some((e) => e.email === email)
    if (isExist) {
      toast.error(t('emailAlreadyExist'))
      return false
    }
    const newPhones = [
      ...emails,
      {
        name,
        email,
        checked: false,
      },
    ]
    // check if phone is already exist
    form.setValue('emails', newPhones)
    return true
  }

  const handleRemoveEmail = (phone: string) => {
    const removeEmail = () => {
      const listPhone = form.getValues('emails') ?? []
      const indexPhone = listPhone.findIndex((e) => e.email === phone)
      if (indexPhone != -1) {
        listPhone.splice(indexPhone, 1)
        form.setValue('emails', listPhone)
      }
    }

    confirmRodal({
      children: ({ onClose }) => {
        return (
          <Rodal
            message={t('descriptionDeleteEmail')}
            onCancel={onClose}
            onClose={onClose}
            onConfirm={() => {
              removeEmail()
            }}
            title={t('titleDeleteEmail')}
          />
        )
      },
    })
  }

  const handleCheckEmail = (email: string, isChecked: boolean) => {
    const listEmail = form.getValues('emails') ?? []
    const indexEmail = listEmail.findIndex((e) => e.email === email)
    if (indexEmail != -1) {
      listEmail[indexEmail].checked = isChecked
      form.setValue('emails', listEmail)
    }
  }

  return {
    isActivateQuicksight,
    form,
    onSubmit,
    handleAddEmail,
    emails,
    handleRemoveEmail,
    handleCheckEmail,
    quicksightData,
    isSaveDisabled,
  }
}

export default useQuicksightChannel
